import { filter, isEmpty } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import AddTransactionModal from './components/modals/AddTransactionModal';
import UploadTransactionsModal from './components/modals/UploadTransactionsModal';
import CreateRuleModal from './components/modals/CreateRuleModal';
import ContextualMenu from '../components/ContextualMenu';

import { FETCH_RULES } from '../api/rules';

import { useUser } from '../hooks/useAuth';

const RULES_COLUMNS = [
  { id: 'id', label: 'Rule ID', alignRight: false },
  { id: 'name', label: 'Rule name', alignRight: false },
  { id: 'description', label: 'Rule description', alignRight: false },
  { id: 'matchField', label: 'Match Field', alignRight: false },
  { id: 'matchType', label: 'Match Type', alignRight: false },
  { id: 'matchValue', label: 'Match Value', alignRight: true },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'active', label: 'Is Rule Active', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, loading) {
  if (loading) {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_transaction) => _transaction?.bookingText?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function Rules() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { userId } = useUser();

  const [openCreateRule, setOpenCreateRule] = useState(false);

  const [rules, setRules] = useState([]);

  const { loading, error, data } = useQuery(FETCH_RULES, { variables: { userId } });

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }

    setRules(data.allRules);
  }, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rules.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rules.length) : 0;

  const filteredUsers = applySortFilter(rules, getComparator(order, orderBy), filterName, loading);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Rules | List">
      <Container>
        <Typography variant="h4" gutterBottom>
          Rules
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} mb={5}>
          <Button
            variant="contained"
            onClick={() => setOpenCreateRule(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create rule
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeholder="Filter by booking text"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, width: '100%' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={RULES_COLUMNS}
                  rowCount={rules.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id } = row;
                      const isItemSelected = selected.indexOf('name') !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, 'name')}
                            />
                          </TableCell>
                          {RULES_COLUMNS.map((column) => (
                            <TableCell align="left">{row[`${column.id}`]}</TableCell>
                          ))}
                          <TableCell align="right">
                            <ContextualMenu
                              primaryActionConfig={{
                                label: 'Dummy Primary',
                                action: () => {}
                              }}
                              secondaryActionConfig={{
                                label: 'Dummy Secondary',
                                action: () => {}
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rules.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CreateRuleModal
        open={openCreateRule}
        onSubmitFinished={() => setOpenCreateRule(false)}
        onSubmitCanceled={() => setOpenCreateRule(false)}
      />
    </Page>
  );
}
