import { useEffect, useState } from 'react';

import { ApolloProvider } from '@apollo/client';

import { useUser } from './hooks/useAuth';
import { getClient } from './api/index';

// routes
import Router from './Router';

// ----------------------------------------------------------------------

export default function AppWrapper() {
  const {
    isAuthenticated,
    user,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useUser();

  const [accessToken, setAccessToken] = useState('');
  const [apolloClient, setApolloClient] = useState(null);

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      setApolloClient(getClient(token));
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
    return <div>Redirecting to login page ...</div>;
  }

  // TODO: this is a hack to get the app to work - add route to login and uncomment this
  if (isLoading || !accessToken || !apolloClient) {
    return <div>Loading ...</div>;
  }

  if (error) {
    return <div>Oops ... {error.message}</div>;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Router />
    </ApolloProvider>
  );
}
