import { useEffect, useState } from 'react';
import { Button, Divider, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from '@mui/styles';

import { useMutation } from '@apollo/client';

import CategoryForm from '../forms/CategoryForm';
import Message, { SEVERITY } from '../../../components/Message';

import { CREATE_CATEGORY } from '../../../api/categories';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles((theme) => ({
  header: {
    // background: theme.palette.primary.main,
    marginBottom: `1.45rem`
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`
  },
  title: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`
  }
}));

export default function AddCategoryModal({ open, onSubmitFinished, onSubmitCanceled }) {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState(null);

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false);
  const [isTriggerClear, setIsTriggerClear] = useState(false);

  const [createCategory, { data, loading, error }] = useMutation(CREATE_CATEGORY);

  useEffect(() => {
    if (loading) return 'Submitting...';

    if (error) {
      setResponseMessage({
        message: `Submission error! ${error.message}`,
        severity: SEVERITY.ERROR
      });
      return;
    }

    if (data) {
      setResponseMessage({
        message: `Submission was successful! ${data}`,
        severity: SEVERITY.SUCCESS
      });
    }
  }, [loading, error, data]);

  const saveFormData = (data) => {
    const { category: name, transactionTypeId } = data;

    if (!name || !transactionTypeId) {
      console.log('The data is missing');
      return;
    }

    createCategory({ variables: { name, transactionTypeId } });
    // onModalClose();
  };

  const handleModalOk = () => {
    setIsTriggerSubmit(true);
  };

  const handleModalCancel = () => {
    setIsTriggerClear(true);
    onSubmitCanceled();
  };

  const handleFormSubmit = (data) => {
    setIsTriggerSubmit(false);
    setIsTriggerClear(false);
    saveFormData(data);
    onSubmitFinished();
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalCancel} maxWidth="lg">
        <DialogTitle>Add Category</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.header}>
            <DialogContentText>Add new categories to classify your transactions</DialogContentText>
          </div>
          <CategoryForm
            isTriggerSubmit={isTriggerSubmit}
            isTriggerClear={isTriggerClear}
            handleFormSubmit={handleFormSubmit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalCancel}>Cancel</Button>
          <Button onClick={handleModalOk}>Save category</Button>
        </DialogActions>
      </Dialog>
      <Message
        isOpen={!!responseMessage}
        message={responseMessage?.message}
        severity={responseMessage?.severity}
      />
    </>
  );
}
