import { useEffect, useState } from 'react';

import { Button, Divider, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

import { useMutation, useQuery } from '@apollo/client';

import RuleForm from '../forms/RuleForm';
import Message, { SEVERITY } from '../../../components/Message';

import { CREATE_RULE } from '../../../api/rules';
import { FETCH_TRANSACTION_TYPES } from '../../../api/transactionTypes';
import { FETCH_PAYMENTS } from '../../../api/payments';

import { useUser } from '../../../hooks/useAuth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles((theme) => ({
  header: {
    // background: theme.palette.primary.main,
    marginBottom: `1.45rem`
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`
  },
  title: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`
  }
}));

export default function CreateRuleModal({
  open,
  onSubmitFinished,
  onSubmitCanceled,
  transactionData,
  ...props
}) {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState(null);
  const [paymentMeans, setPaymentMeans] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false);
  const [isTriggerClear, setIsTriggerClear] = useState(false);

  const { userId } = useUser();

  // const { transactionData: { bookingText } = {} } = props;

  const [createRule, { data, loading, error }] = useMutation(CREATE_RULE);
  const {
    data: paymentMeansData,
    loading: loadingPaymentMeans,
    error: errorPaymentMeans
  } = useQuery(FETCH_PAYMENTS);
  const {
    data: transactionTypesData,
    loading: loadingTransactionTypes,
    error: errorTransactionTypes
  } = useQuery(FETCH_TRANSACTION_TYPES);

  const formatData = (data) => data.map(({ name, code }) => ({ label: name, value: code }));

  useEffect(() => {
    if (isEmpty(paymentMeansData)) {
      return;
    }

    setPaymentMeans(formatData(paymentMeansData.allPayments));
  }, [paymentMeansData]);

  useEffect(() => {
    if (isEmpty(transactionTypesData)) {
      return;
    }

    setTransactionTypes(formatData(transactionTypesData.allTransactionTypes));
  }, [transactionTypesData]);

  useEffect(() => {
    if (loading) return 'Submitting...';

    if (error) {
      setResponseMessage({
        message: `Submission error! ${error.message}`,
        severity: SEVERITY.ERROR
      });
      return;
    }

    if (data) {
      setResponseMessage({
        message: `Submission was successful! ${JSON.stringify(data)}`,
        severity: SEVERITY.SUCCESS
      });
    }
  }, [loading, error, data]);

  const onFormError = (data) => {
    console.log('Error', data);
  };

  const saveFormData = (data) => {
    const { name, description, matchField, matchType, matchValue, category, active } = data;

    if (!name || !description || !matchField || !matchType || !matchValue || !category || !active) {
      console.log('The data is missing');
      return;
    }

    createRule({
      variables: {
        ...data,
        userId
      }
    });

    // onClose();
  };

  const handleModalOk = () => {
    // Trigger form submit
    setIsTriggerSubmit(true);
  };

  const handleModalCancel = () => {
    // TODO: Clear form
    setIsTriggerClear(true);
    onSubmitCanceled();
  };

  const handleFormSubmit = (data) => {
    setIsTriggerSubmit(false);
    setIsTriggerClear(false);
    saveFormData(data);
    onSubmitFinished();
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalCancel} maxWidth="lg">
        <DialogTitle>Create Rule</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.header}>
            <DialogContentText>Rule details:</DialogContentText>
          </div>
          <RuleForm
            isTriggerSubmit={isTriggerSubmit}
            isTriggerClear={isTriggerClear}
            handleFormSubmit={handleFormSubmit}
            prefillData={{ ...transactionData }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalCancel}>Cancel</Button>
          <Button onClick={handleModalOk}>Save category</Button>
        </DialogActions>
      </Dialog>
      <Message
        isOpen={!!responseMessage}
        message={responseMessage?.message}
        severity={responseMessage?.severity}
      />
    </>
  );
}
