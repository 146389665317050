import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

import { filter, isEmpty } from 'lodash';

import { useMutation, useQuery } from '@apollo/client';

import TransactionForm from '../forms/TransactionForm';
import Message, { SEVERITY } from '../../../components/Message';

import { CREATE_CATEGORY } from '../../../api/categories';

import { FETCH_TRANSACTION_TYPES } from '../../../api/transactionTypes';
import { FETCH_PAYMENTS } from '../../../api/payments';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles((theme) => ({
  header: {
    // background: theme.palette.primary.main,
    marginBottom: `1.45rem`
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`
  },
  title: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`
  }
}));

export default function AddTransactionModal({ open, onSubmit, onClose }) {
  const classes = useStyles();
  const [submitForm, setSubmitForm] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [paymentMeans, setPaymentMeans] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);

  const [createCategory, { data, loading, error }] = useMutation(CREATE_CATEGORY);
  const {
    data: paymentMeansData,
    loading: loadingPaymentMeans,
    error: errorPaymentMeans
  } = useQuery(FETCH_PAYMENTS);
  const {
    data: transactionTypesData,
    loading: loadingTransactionTypes,
    error: errorTransactionTypes
  } = useQuery(FETCH_TRANSACTION_TYPES);

  const formatData = (data) => data.map(({ name, code }) => ({ label: name, value: code }));

  useEffect(() => {
    if (isEmpty(paymentMeansData)) {
      return;
    }

    setPaymentMeans(formatData(paymentMeansData.allPayments));
  }, [paymentMeansData]);

  useEffect(() => {
    if (isEmpty(transactionTypesData)) {
      return;
    }

    setTransactionTypes(formatData(transactionTypesData.allTransactionTypes));
  }, [transactionTypesData]);

  useEffect(() => {
    if (loading) return 'Submitting...';

    if (error) {
      setResponseMessage({
        message: `Submission error! ${error.message}`,
        severity: SEVERITY.ERROR
      });
      return;
    }

    if (data) {
      setResponseMessage({
        message: `Submission was successful! ${data}`,
        severity: SEVERITY.SUCCESS
      });
    }
  }, [loading, error, data]);

  const onFormSubmit = (data) => {
    setSubmitForm(false);

    const { category: name, code } = data;

    if (!name || !code) {
      console.log('The data is missing');
      return;
    }

    createCategory({ variables: { name, code: parseInt(code, 10) } });
    onClose();
  };

  const handleModalClose = () => {
    onClose();
    setSubmitForm(false);
  };

  const handleModalSubmit = () => {
    setSubmitForm(true);
  };
  return (
    <>
      <Dialog open={open} onClose={handleModalClose} maxWidth="lg">
        <DialogTitle>Add Transaction</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.header}>
            <DialogContentText>Add new transaction</DialogContentText>
          </div>
          <TransactionForm
            submitForm={submitForm}
            onSubmit={onFormSubmit}
            paymentMeans={paymentMeans}
            transactionTypes={transactionTypes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleModalSubmit}>Save category</Button>
        </DialogActions>
      </Dialog>
      <Message
        isOpen={!!responseMessage}
        message={responseMessage?.message}
        severity={responseMessage?.severity}
      />
    </>
  );
}
