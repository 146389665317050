import { gql } from '@apollo/client';

export const CREATE_RULE = gql`
  mutation CreateRule(
    $userId: String!
    $name: String!
    $description: String!
    $matchField: String!
    $matchType: String!
    $matchValue: String!
    $category: String!
    $active: Boolean!
  ) {
    createRule(
      userId: $userId
      name: $name
      description: $description
      matchField: $matchField
      matchType: $matchType
      matchValue: $matchValue
      category: $category
      active: $active
    ) {
      id
      userId
      name
      description
      matchField
      matchType
      matchValue
      category
      active
    }
  }
`;

export const FETCH_RULES = gql`
  query AllRules($userId: String!) {
    allRules(userId: $userId) {
      id
      userId
      name
      description
      matchField
      matchType
      matchValue
      category
      active
    }
  }
`;
