import { gql } from '@apollo/client';

export const FETCH_PAYMENTS = gql`
  query AllPayments {
    allPayments {
      id
      name
    }
  }
`;
