import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function MuiSelect({ name, value, label, onChange, options, className, ...rest }) {
  return (
    <Select className={className} name={name} value={value} label={label} onChange={onChange}>
      {options?.map(({ label, value }) => (
        <MenuItem value={value} label={label}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default MuiSelect;
