import { gql } from '@apollo/client';

export const GET_TOTALS = gql`
  query Totals {
    totalDebit
    totalCredit
    totalDebitLastMonth
    totalCreditLastMonth
  }
`;

export const GET_TOTAL_DEBIT = gql`
  query TotalDebit {
    totalDebit
  }
`;

export const GET_TOTAL_CREDIT = gql`
  query TotalCredit {
    totalCredit
  }
`;

export const GET_TOTAL_DEBIT_LAST_MONTH = gql`
  query TotalDebitLastMonth {
    totalDebitLastMonth
  }
`;

export const GET_TOTAL_CREDIT_LAST_MONTH = gql`
  query TotalCreditLastMonth {
    totalCreditLastMonth
  }
`;

export const GET_TOTAL_STANDING_ORDERS_LAST_MONTH = gql`
  query TotalStandingOrdersLastMonth {
    totalStandingOrdersLastMonth
  }
`;

export const GET_TOTAL_LIVING_EXPENSES_LAST_MONTH = gql`
  query TotalLivingExpensesLastMonth {
    totalLivingExpensesLastMonth
  }
`;

export const GET_TOTAL_BALANCE = gql`
  query TotalBalance {
    totalBalance
  }
`;

export const GET_TOTAL_BALANCE_LAST_MONTH = gql`
  query TotalBalanceLastMonth {
    totalBalanceLastMonth
  }
`;

export const GET_TOTAL_BREAKDOWN_BY_CATEGORY = gql`
  query TotalBreakdownByCategory {
    totalBreakdownByCategory
  }
`;
