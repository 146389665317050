import { gql } from '@apollo/client';

export const FETCH_DIMENSIONS = gql`
  query AllDimensions {
    allDimensions {
      id
      name
      key
    }
  }
`;

export const FETCH_DIMENSION_WITH_CATEGORIES = gql`
  query AllDimensionWithCategories($userId: String!) {
    allDimensionsWithCategories(userId: $userId) {
      id
      name
      key
      Categories {
        id
        name
      }
    }
  }
`;

export const UPDATE_DIMENSION = gql`
  mutation UpdateDimension($id: String!, $name: String!, $key: String!) {
    updateDimension(id: $id, name: $name, key: $key) {
      id
      name
      key
    }
  }
`;

export const CREATE_DIMENSION = gql`
  mutation CreateDimension($name: String!, $key: String!) {
    createDimension(name: $name, key: $key) {
      name
      key
    }
  }
`;

export const DELETE_DIMENSION = gql`
  mutation DeleteDimension($id: String!) {
    deleteDimension(id: $id)
  }
`;

export const CREATE_DIMENSION_CATEGORY = gql`
  mutation CreateDimensionCategory($dimensionId: String!, $categoryId: String!) {
    createDimensionCategory(dimensionId: $dimensionId, categoryId: $categoryId) {
      dimensionId
      categoryId
    }
  }
`;

export const CREATE_DIMENSION_CATEGORIES_IN_BULK = gql`
  mutation CreateDimensionCategoriesInBulk(
    $userId: String!
    $dimensionId: String!
    $categoryIds: [String]!
  ) {
    createDimensionCategoriesInBulk(
      userId: $userId
      dimensionId: $dimensionId
      categoryIds: $categoryIds
    )
  }
`;

export const DELETE_DIMENSION_CATEGORIES_IN_BULK = gql`
  mutation DeleteDimensionCategoriesInBulk($userId: String!, $dimensionId: String!) {
    deleteDimensionCategoriesInBulk(userId: $userId, dimensionId: $dimensionId)
  }
`;
