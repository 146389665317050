export const MATCH_FIELDS = {
  BOOKING_TEXT: 'bookingText',
  ORIGINAL_TYPE: 'originalType',
  ORIGINAL_CATEGORY: 'originalCategory',
  AMOUNT: 'amount'
};

export const MATCH_TYPES = {
  EXACT: 'exact',
  CONTAINS: 'contains',
  STARTS_WITH: 'starts_with',
  ENDS_WITH: 'ends_with'
};
