// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'transactions',
    path: '/transactions',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'categories',
    path: '/categories',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'dimensions',
    path: '/dimensions',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'rules',
    path: '/rules',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'transactionTypes',
    path: '/transaction-types',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'user',
    path: '/user',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon('eva:lock-fill')
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill')
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill')
  }
];

export default sidebarConfig;
