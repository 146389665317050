import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import NotFound from './pages/Page404';
import Categories from './pages/Categories';
import TransactionTypes from './pages/TransactionTypes';
import Transactions from './pages/Transactions';
import Dimensions from './pages/Dimensions';
import Rules from './pages/Rules';
import ProtectedRoute from './components/ProtectedRoute';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route path="dashboard" element={<ProtectedRoute component={DashboardApp} />} />
        <Route path="transactions" element={<ProtectedRoute component={Transactions} />} />
        <Route path="user" element={<ProtectedRoute component={User} />} />
        <Route path="categories" element={<ProtectedRoute component={Categories} />} />
        <Route path="dimensions" element={<ProtectedRoute component={Dimensions} />} />
        <Route path="transaction-types" element={<ProtectedRoute component={TransactionTypes} />} />
        <Route path="rules" element={<ProtectedRoute component={Rules} />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  );
}
