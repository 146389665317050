import { gql } from '@apollo/client';

export const FETCH_CATEGORIES = gql`
  query AllCategories {
    allCategories {
      id
      name
      transactionTypeId
      TransactionType {
        id
        name
      }
    }
  }
`;

export const FETCH_CATEGORIES_BY_DIMENSION = gql`
  query AllCategoriesByDimension($userId: String!, $dimensionId: String!) {
    allCategoriesByDimension(userId: $userId, dimensionId: $dimensionId) {
      id
      name
      Categories {
        id
        name
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $transactionTypeId: String!) {
    createCategory(name: $name, transactionTypeId: $transactionTypeId) {
      id
      name
      transactionTypeId
    }
  }
`;
