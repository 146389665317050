// material
import { Box, Grid, Container, Typography, Divider } from '@mui/material';
// components
import { useQuery } from '@apollo/client';
import Page from '../components/Page';
import {
  AppTasks,
  TotalSpending,
  LastMonthIncome,
  LastMonthSpending,
  AppNewsUpdate,
  TotalIncome,
  AppOrderTimeline,
  SpendingByCategory,
  SpendingByMonth,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  LastMonthStandingOrders,
  LastMonthLivingExpenses,
  LastMonthBalance,
  TotalBalance
} from '../sections/@dashboard/app';

import {
  GET_TOTAL_CREDIT,
  GET_TOTAL_DEBIT,
  GET_TOTAL_DEBIT_LAST_MONTH,
  GET_TOTAL_CREDIT_LAST_MONTH,
  GET_TOTAL_STANDING_ORDERS_LAST_MONTH,
  GET_TOTAL_LIVING_EXPENSES_LAST_MONTH,
  GET_TOTAL_BALANCE,
  GET_TOTAL_BALANCE_LAST_MONTH,
  GET_TOTAL_BREAKDOWN_BY_CATEGORY
} from '../api/dashboard';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  // const { data: totalsData } = useQuery(GET_TOTALS);
  const { data: { totalDebit } = { totalDebit: 0 } } = useQuery(GET_TOTAL_DEBIT);
  const { data: { totalCredit } = { totalCredit: 0 } } = useQuery(GET_TOTAL_CREDIT);
  const { data: { totalDebitLastMonth } = { totalDebitLastMonth: 0 } } = useQuery(
    GET_TOTAL_DEBIT_LAST_MONTH
  );
  const { data: { totalCreditLastMonth } = { totalCreditLastMonth: 0 } } = useQuery(
    GET_TOTAL_CREDIT_LAST_MONTH
  );
  const { data: { totalStandingOrdersLastMonth } = { totalStandingOrdersLastMonth: 0 } } = useQuery(
    GET_TOTAL_STANDING_ORDERS_LAST_MONTH
  );
  const { data: { totalLivingExpensesLastMonth } = { totalLivingExpensesLastMonth: 0 } } = useQuery(
    GET_TOTAL_LIVING_EXPENSES_LAST_MONTH
  );
  const { data: { totalBalance } = { totalBalance: 0 } } = useQuery(GET_TOTAL_BALANCE);
  const { data: { totalBalanceLastMonth } = { totalBalanceLastMonth: 0 } } = useQuery(
    GET_TOTAL_BALANCE_LAST_MONTH
  );

  const { data: { totalBreakdownByCategory } = { totalBreakdownByCategory: [] } } = useQuery(
    GET_TOTAL_BREAKDOWN_BY_CATEGORY
  );

  console.log('totalBreakdownByCategory', totalBreakdownByCategory);

  const dataAdapter = (data) => data / 100;

  return (
    <Page title="Dashboard | Minimal-UI">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TotalIncome data={dataAdapter(totalCredit)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TotalSpending data={dataAdapter(totalDebit)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <LastMonthIncome data={dataAdapter(totalCreditLastMonth)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <LastMonthSpending data={dataAdapter(totalDebitLastMonth)} />
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <LastMonthStandingOrders data={dataAdapter(totalStandingOrdersLastMonth)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <LastMonthLivingExpenses data={dataAdapter(totalLivingExpensesLastMonth)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <TotalBalance data={dataAdapter(totalBalance)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <LastMonthBalance data={dataAdapter(totalBalanceLastMonth)} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <SpendingByMonth />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SpendingByCategory />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
