import { gql } from '@apollo/client';

export const FETCH_TRANSACTION_TYPES = gql`
  query AllTransactionTypes {
    allTransactionTypes {
      id
      name
    }
  }
`;
