import { gql } from '@apollo/client';

export const FETCH_TRANSACTIONS = gql`
  query AllTransactions($userId: String!) {
    allTransactions(userId: $userId) {
      id
      userId
      cardNumber
      transactionDate
      bookingDate
      bookingText
      originalCategory
      category
      originalType
      amount
      currency
      initiatorAccount
      initiatorAccountBankCode
      initiatorIBAN
      amazonPoints
      primePoints
      originalAmount
      originalCurrency
    }
  }
`;

export const FETCH_TRANSACTIONS_INCLUDE_CATEGORY = gql`
  query AllTransactionIncludeCategory($userId: String!) {
    allTransactionsIncludeCategory(userId: $userId) {
      id
      userId
      cardNumber
      transactionDate
      bookingDate
      bookingText
      originalCategory
      category
      CategoryAlias {
        id
        name
      }
      originalType
      amount
      currency
      initiatorAccount
      initiatorAccountBankCode
      initiatorIBAN
      amazonPoints
      primePoints
      originalAmount
      originalCurrency
    }
  }
`;

export const CREATE_TRANSACTIONS_IN_BULK = gql`
  mutation createTransactions($transactions: [TransactionInput]!, $userId: String!) {
    createTransactions(transactions: $transactions, userId: $userId)
  }
`;
