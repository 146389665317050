import { Button, Grid, Typography, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';

import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';

import { FormField } from './FormField';
import { FIELD_TYPES } from './constants';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '100%',
    height: '3rem'
  },
  formLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '3rem'
  }
}));

function TransactionForm({ submitForm, onSubmit, paymentMeans, transactionTypes }) {
  const classes = useStyles();
  const methods = useForm();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (!submitForm) {
      return;
    }

    handleSubmit(onSubmit)();
  }, [submitForm]);

  return (
    <FormProvider {...methods}>
      <form>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <span className={classes.formLabel}>Card Number / Account</span>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="cardNumber"
                control={control}
                placeholder="Card number"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={paymentMeans}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Transaction Date</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="transactionDate"
                control={control}
                placeholder="Transaction Date"
                classes={classes}
                type={FIELD_TYPES.DATE_PICKER}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Transaction Text</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="bookingText"
                control={control}
                placeholder="Booking text"
                classes={classes}
                type={FIELD_TYPES.TEXT_INPUT}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Category</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="category"
                control={control}
                placeholder="Category"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={[
                  { span: 'One', value: 1 },
                  { span: 'Two', value: 2 }
                ]}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Transaction Type</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="type"
                control={control}
                placeholder="Type"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={transactionTypes}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Amount</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="amount"
                control={control}
                placeholder="Amount"
                classes={classes}
                type={FIELD_TYPES.TEXT_INPUT}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
}

export default TransactionForm;
