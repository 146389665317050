import { Button, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { FormField } from './FormField';
import { FIELD_TYPES } from './constants';
import { FETCH_TRANSACTION_TYPES } from '../../../api/transactionTypes';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '100%',
    height: '3rem'
  },
  formLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '3rem'
  }
}));

function CategoryForm({ isTriggerSubmit, isTriggerClear, handleFormSubmit }) {
  const classes = useStyles();
  const methods = useForm();

  const { data: transactionTypesData } = useQuery(FETCH_TRANSACTION_TYPES);
  const [transactionTypes, setTransactionTypes] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (transactionTypesData) {
      setTransactionTypes(
        transactionTypesData.allTransactionTypes.map((transactionType) => ({
          value: transactionType.id,
          label: transactionType.name
        }))
      );
    }
  }, [transactionTypesData]);

  useEffect(() => {
    if (isTriggerSubmit) {
      handleSubmit(handleFormSubmit)();
    }
  }, [isTriggerSubmit]);

  useEffect(() => {
    if (isTriggerClear) {
      methods.reset();
    }
  }, [isTriggerClear]);

  return (
    <FormProvider {...methods}>
      <form>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Category name</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="category"
                control={control}
                rules={{ required: 'Category name is required' }}
                placeholder="Insert category name"
                classes={classes}
                type={FIELD_TYPES.TEXT}
                error={errors.category}
              />
            </Grid>
            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Transaction Type</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="transactionTypeId"
                control={control}
                rules={{ required: 'Transaction type is required' }}
                placeholder="Select transaction type"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={transactionTypes}
                error={errors.category}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
}

export default CategoryForm;
