import React from 'react';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCSVReader } from 'react-papaparse';

const useStyles = makeStyles((theme) => ({
  csvReader: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    minWidth: '640px'
  },
  browseFile: {
    display: 'flex',
    width: '100%',
    marginBottom: 10
  },
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    borderRadius: 8,
    marginBottom: 10
  },
  remove: {
    display: 'flex',
    borderRadius: 8,
    padding: '0 20px',
    width: '100%',
    lineHeight: 2.5,
    border: '1px solid #ccc',
    justifyContent: 'center'
  },
  progressBarBackgroundColor: {
    backgroundColor: '#ccc'
  }
}));

export default function CSVFileReader({ onFileRead }) {
  const { CSVReader } = useCSVReader();
  const classes = useStyles();

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        onFileRead(results);
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <>
          <div className={classes.csvReader}>
            <Button
              type="button"
              variant="contained"
              {...getRootProps()}
              className={classes.browseFile}
            >
              Browse file
            </Button>
            <div className={classes.acceptedFile}>{acceptedFile && acceptedFile.name}</div>
            <Button {...getRemoveFileProps()} className={classes.remove}>
              Remove
            </Button>
          </div>
          <ProgressBar className={classes.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}
