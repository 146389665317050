import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@mui/styles';

import moment from 'moment';

import Dinero from 'dinero.js';

import { Button, TextField, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { formatISO } from 'date-fns';
import DatePicker from '../forms/DatePicker';

import Message, { SEVERITY } from '../../../components/Message';

import CSVFileReader from './CSVFileReader';

import { CREATE_TRANSACTIONS_IN_BULK } from '../../../api/transactions';

import { transactionsMappers, UPLOAD_FILE_SOURCE } from '../constants';

import { useUser } from '../../../hooks/useAuth';

const { toNumber } = require('lodash');

const useStyles = makeStyles((theme) => ({
  // header: {
  //   // background: theme.palette.primary.main,
  //   marginBottom: `1.45rem`
  // },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`
  },
  title: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`
  },
  dialog: {
    minWidth: 600,
    minHeight: 450

    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 640,
    // height: 480,
    // border: '2px solid #000'
  },
  dialogContent: {
    marginTop: `1rem`
  },
  dateControl: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
    minWidth: '100%'
  },
  datePickerStart: {
    marginRight: 10,
    width: 'calc(50% - 10px)'
  },
  datePickerEnd: {
    width: 'calc(50% - 10px)'
  }
}));

export default function UploadTransactionsModal({ open, onSubmit, onClose }) {
  const classes = useStyles();
  const [uploadData, setUploadData] = useState([]);

  const { user } = useUser();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (user) {
      setUserId(user.sub.split('|')[1]);
    }
  }, [user]);

  const [createTransactions] = useMutation(CREATE_TRANSACTIONS_IN_BULK);

  const handleModalClose = () => {
    onClose();
  };

  const detectFileSource = (fileData) => {
    const fileHeader = fileData[0];

    const transactionMapper = transactionsMappers.find(({ source, header, mapper }) => {
      if (fileHeader.every((item) => Object.values(header).includes(item))) {
        return true;
      }

      return false;
    });

    if (!transactionMapper) {
      console.log('Transaction Mapper not found');
      return;
    }

    console.log('Transaction Mapper found', transactionMapper);

    return transactionMapper;
  };

  const adjustAmazonFileData = (fileData) => {
    const fileHeader = fileData[0];

    // in some cases the first row is empty and it has only one element - empty string so we need to remove it
    if (fileHeader.length === 1) {
      return fileData.slice(1);
    }

    return fileData;
  };

  const convertSign = (amount, signToConvert, flip) => {
    const sign = Math.sign(amount);

    if (flip) {
      return amount * -1;
    }

    if (sign === signToConvert) {
      return amount;
    }

    if (sign === -1) {
      if (signToConvert === 1) {
        return amount * -1;
      }

      return amount;
    }

    return amount;
  };

  const convertAmazonAmountSign = (data) => {
    const { amount = 0, originalAmount = 0 } = data;
    const amountInt = parseInt(amount, 10);
    const originalAmountInt = parseInt(originalAmount, 10);

    const amountPositive = Dinero({ amount: amountInt }).isPositive();
    const originalAmountPositive = Dinero({ amount: originalAmountInt }).isPositive();

    const convertedAmount = amount
      ? convertSign(amountInt, amountPositive ? -1 : 1, true)
      : amountInt;
    const convertedOriginalAmount = originalAmountInt
      ? convertSign(originalAmountInt, originalAmountPositive ? -1 : 1, true)
      : originalAmountInt;

    if (Number.isNaN(convertedAmount || Number.isNaN(convertedOriginalAmount))) {
      console.log('Converted Amount is not a number', data, amount, originalAmount);
    }

    data.amount = convertedAmount.toString();
    data.originalAmount = convertedOriginalAmount.toString();
  };

  const convertAmountToCents = (amount) => {
    if (!amount) {
      return 0;
    }

    const formattedAmount = amount.replace(',', '.').replace('.', '');

    // const [integralAmount, fractionAmount] = amount.split(',');
    // const centsAmount = integralAmount * 100 + toNumber(fractionAmount);

    // return centsAmount;

    return formattedAmount.toString();
  };

  const convertDate = (data) => {
    ['transactionDate', 'bookingDate'].forEach((dateField) => {
      const [dd, mm, yyyy] = data[dateField] ? data[dateField].split('.') : [];
      data[dateField] = data[dateField] ? formatISO(new Date(yyyy, mm, dd)) : null;
    });
  };

  const handleModalSubmit = () => {
    // this strips the first row which is the empty row - amazon specific
    const adjustedData = adjustAmazonFileData(uploadData);

    // this detects the file source and returns the mapper
    const { mapper, source } = detectFileSource(adjustedData);

    if (!source) {
      // TODO: show error message
      console.log('Unsupported data source - we can not map the data');
      return;
    }

    // this converts the data to the format we need
    const columnNames = Object.values(mapper);

    console.log('adjusted data length: ', adjustedData.length);

    // upload data to the server
    const formattedData = adjustedData.slice(1).map((row) => {
      const data = row.reduce((acc, value, index) => {
        const columnName = columnNames[index];

        if (columnName === 'amount' || columnName === 'originalAmount') {
          console.log('Amount value', value);
          acc[columnName] = convertAmountToCents(value);
        } else {
          acc[columnName] = value;
        }

        return acc;
      }, {});

      // in case of amazon transactions we need to add currency for each transaction - thus condition to avoid amazon points
      if (source === UPLOAD_FILE_SOURCE.AMAZON_LBB) {
        if (data.amount || (data.originalAmount && data.originalAmount !== '0,00')) {
          data.currency = 'EUR';
        }

        convertAmazonAmountSign(data);
        // convertDate(data);
      }

      if (source === UPLOAD_FILE_SOURCE.COMMERZBANK_CREDIT_CARD) {
        // convertDate(data);
      }

      // all commerzbank transactions are in EUR - thus we need to add original currency and amount
      if (source === UPLOAD_FILE_SOURCE.COMMERZBANK) {
        data.originalAmount = data.amount;
        data.originalCurrency = data.currency;
      }

      convertDate(data);

      return data.amount || data.originalAmount ? data : null;
    });

    console.log('formattedData', formattedData);
    console.log('formatted data length: ', formattedData.length);

    createTransactions({ variables: { transactions: formattedData, userId } });

    // close the modal
    onClose();
  };

  const handleFileRead = ({ data, errors }) => {
    // console.log('Data: ', data);

    if (errors.length) {
      console.log('There was an error uploading file');
      return;
    }

    setUploadData(data);
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalClose} maxWidth="lg" className={classes.dialog}>
        <DialogTitle>Upload Transactions</DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.header}>
            <DialogContentText>Select CSV file with transactions</DialogContentText>
          </div>
          <CSVFileReader onFileRead={handleFileRead} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleModalSubmit}>Save category</Button>
        </DialogActions>
      </Dialog>
      {/* <Message
        isOpen={!!responseMessage}
        message={responseMessage?.message}
        severity={responseMessage?.severity}
      /> */}
    </>
  );
}
