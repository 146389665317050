import { useEffect, useState } from 'react';

import { Button, Divider, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

import { isEmpty } from 'lodash';

import { useQuery, useMutation } from '@apollo/client';

import Message, { SEVERITY } from '../../../components/Message';

import { FETCH_CATEGORIES } from '../../../api/categories';
import {
  DELETE_DIMENSION_CATEGORIES_IN_BULK,
  CREATE_DIMENSION_CATEGORIES_IN_BULK
} from '../../../api/dimensions';

import { useUser } from '../../../hooks/useAuth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles((theme) => ({
  header: {
    // background: theme.palette.primary.main,
    marginBottom: `1.45rem`
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`
  },
  title: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`
  }
}));

export default function DimensionCategoryMapperModal({
  open,
  onSubmitFinished,
  onSubmitCanceled,
  selectedDimension = {}
}) {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState(null);

  const [isTriggerSubmit, setIsTriggerSubmit] = useState(false);
  const [isTriggerClear, setIsTriggerClear] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const { loading, error, data: categoriesData } = useQuery(FETCH_CATEGORIES);

  const { userId } = useUser();

  const [
    deleteDimensionCategoriesInBulk,
    {
      data: deleteDimensionCategories,
      loading: loadingDeleteDimensionCategories,
      error: errorDeleteDimensionCategories
    }
  ] = useMutation(DELETE_DIMENSION_CATEGORIES_IN_BULK);

  const [
    createDimensionCategoriesInBulk,
    {
      data: createDimensionCategories,
      loading: loadingCreateDimensionCategories,
      error: errorCreateDimensionCategories
    }
  ] = useMutation(CREATE_DIMENSION_CATEGORIES_IN_BULK);

  useEffect(() => {
    if (categoriesData) {
      const { allCategories } = categoriesData;
      setAllCategories(allCategories);
    }
  }, [categoriesData]);

  useEffect(() => {
    const categories = selectedDimension?.Categories.map((item) => {
      const { id, name } = item;
      return {
        id,
        name,
        checked: true
      };
    });

    setSelectedCategories(categories);
  }, [selectedDimension]);

  // useEffect(() => {
  //   if (loading) return 'Submitting...';

  //   if (error) {
  //     setResponseMessage({
  //       message: `Submission error! ${error.message}`,
  //       severity: SEVERITY.ERROR
  //     });
  //     return;
  //   }

  //   if (categoriesData) {
  //     setResponseMessage({
  //       message: `Submission was successful! ${categoriesData}`,
  //       severity: SEVERITY.SUCCESS
  //     });
  //   }
  // }, [loading, error, categoriesData]);

  const saveFormData = async (data) => {
    const checkedCategories = Object.keys(data).filter((key) => data[key]);

    const isListEmpty = isEmpty(checkedCategories);

    if (isListEmpty) {
      setResponseMessage({
        message: `You've unselected all categories.`,
        severity: SEVERITY.INFO
      });

      // TODO: later we can add confirmation dialog here
      // to make sure that user intentionally unselected all categories
      // return;

      // TODO: remove all categories from dimension is not implemented correctly
      const resultOfDeletion = await deleteDimensionCategoriesInBulk({
        variables: {
          dimensionId: selectedDimension.id
        }
      });
    }

    await createDimensionCategoriesInBulk({
      variables: {
        userId,
        dimensionId: selectedDimension.id,
        categoryIds: checkedCategories
      }
    });

    if (loadingCreateDimensionCategories) return 'Submitting...';

    if (errorCreateDimensionCategories) {
      setResponseMessage({
        message: `Submission error! ${errorCreateDimensionCategories.message}`,
        severity: SEVERITY.ERROR
      });
      return;
    }

    if (createDimensionCategories) {
      setResponseMessage({
        message: `Submission was successful! ${createDimensionCategories}`,
        severity: SEVERITY.SUCCESS
      });

      // reload categories
    }
  };

  const handleModalOk = () => {
    setIsTriggerSubmit(true);
  };

  const handleModalCancel = () => {
    setIsTriggerClear(true);
    onSubmitCanceled();
  };

  const handleFormSubmit = async (data) => {
    setIsTriggerSubmit(false);
    setIsTriggerClear(false);

    await saveFormData(data);
    onSubmitFinished();
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalCancel} maxWidth="lg">
        <DialogTitle>Add Category</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.header}>
            <DialogContentText>Select categories that belong to this dimension</DialogContentText>
            <SwitchesGroup
              allCategories={allCategories}
              selectedCategories={selectedCategories}
              isTriggerSubmit={isTriggerSubmit}
              onTriggerSubmit={handleFormSubmit}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalCancel}>Cancel</Button>
          <Button onClick={handleModalOk}>Save category</Button>
        </DialogActions>
      </Dialog>
      <Message
        isOpen={!!responseMessage}
        message={responseMessage?.message}
        severity={responseMessage?.severity}
      />
    </>
  );
}

function SwitchesGroup({ allCategories, selectedCategories, isTriggerSubmit, onTriggerSubmit }) {
  const isCategorySelected = (category) => {
    const { id } = category;
    const selectedCategory = selectedCategories.find((item) => item.id === id);
    return !!selectedCategory;
  };

  const [state, setState] = useState({
    ...allCategories.reduce((acc, category) => {
      acc[category.id] = isCategorySelected(category);
      return acc;
    }, {})
  });

  useEffect(() => {
    if (isTriggerSubmit) {
      onTriggerSubmit(state);
    }
  }, [isTriggerSubmit]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Assign Categories</FormLabel>
      <FormGroup>
        {allCategories.map((category) => (
          <FormControlLabel
            control={
              <Switch checked={state[category.id]} onChange={handleChange} name={category.id} />
            }
            label={category.name}
            key={category.id}
          />
        ))}
      </FormGroup>
      <FormHelperText>Be careful!!!</FormHelperText>
    </FormControl>
  );
}
