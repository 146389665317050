import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FIELD_TYPES } from './constants';
import DatePicker from './DatePicker';
import MuiSelect from './Select';

export function FormField({ name, control, classes, type, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { errors, isDirty: isFormDirty, isValid }
      }) => (
        <FieldInput
          type={type}
          className={classes.input}
          onChange={onChange}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

function FieldInput({ type, className, onChange, value, ...rest }) {
  switch (type) {
    case FIELD_TYPES.TEXT_INPUT:
      return <TextField className={className} onChange={onChange} value={value} {...rest} />;
    case FIELD_TYPES.DATE_PICKER:
      return (
        <DatePicker
          label="Date desktop"
          inputFormat="MM/dd/yyyy"
          renderInput={(params) => <TextField {...params} />}
          onChange={onChange}
          className={className}
          {...rest}
        />
      );
    case FIELD_TYPES.SELECT:
      return <MuiSelect className={className} onChange={onChange} value={value} {...rest} />;
    case FIELD_TYPES.CHECKBOX:
      return (
        <input type="checkbox" className={className} onChange={onChange} value={value} {...rest} />
      );
    default:
      return <TextField className={className} onChange={onChange} value={value} {...rest} />;
  }
}
