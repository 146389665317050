import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';

export const getClient = (token) =>
  new ApolloClient({
    uri: process.env.REACT_APP_API_SERVER_URL,
    cache: new InMemoryCache(),
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });
