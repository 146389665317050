import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useUser = () => {
  const { isAuthenticated, user, isLoading, error, ...rest } = useAuth0();
  const userId = user?.sub.split('|')[1];
  return { isAuthenticated, user, isLoading, userId, ...rest };
};

const useAccessToken = async () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  const setToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
    console.log('token: ', token);
  };

  useEffect(() => {
    setToken();
  }, [getAccessTokenSilently]);

  const getToken = () => accessToken;

  return { getToken };
};

export { useUser, useAccessToken };
