// import { ALL_TRANSACTION_SOURCES } from '../../../api/transactionSources';
// import { ALL_TRANSACTION_UPLOAD_PERIODS } from '../../../api/transactionUploadPeriods';

export const UPLOAD_FILE_SOURCE = {
  COMMERZBANK: 'COMMERZBANK',
  AMAZON_LBB: 'AMAZON_LBB',
  COMMERZBANK_CREDIT_CARD: 'COMMERZBANK_CREDIT_CARD'
};

// Move to a util function outside of component
export const amazonTransactionsFileHeader = {
  0: 'Kreditkartennummer (teilmaskiert)',
  1: 'Transaktionsdatum',
  2: 'Buchungsdatum',
  3: 'Händler (Name, Stadt & Land)',
  4: 'Umsatzkategorie',
  5: 'Betrag in Fremdwährung',
  6: 'Einheit Fremdwährung',
  7: 'Umrechnungskurs',
  8: 'Betrag in Euro',
  9: 'Amazon Punkte',
  10: 'Prime Punkte'
};

export const amazonTransactionsMapper = {
  'Kreditkartennummer (teilmaskiert)': 'cardNumber',
  Transaktionsdatum: 'transactionDate',
  Buchungsdatum: 'bookingDate',
  'Händler (Name, Stadt & Land)': 'bookingText',
  Umsatzkategorie: 'originalCategory',
  'Betrag in Fremdwährung': 'originalAmount',
  'Einheit Fremdwährung': 'originalCurrency',
  Umrechnungskurs: 'exchangeRate',
  'Betrag in Euro': 'amount',
  'Amazon Punkte': 'amazonPoints',
  'Prime Punkte': 'primePoints'
};

export const commerzbankTransactionsFileHeader = {
  0: 'Transaction date',
  1: 'Value date',
  2: 'Transaction type',
  3: 'Booking text',
  4: 'Amount',
  5: 'Currency',
  6: 'Account of initiator',
  7: 'Bank code of account of initiator',
  8: 'IBAN of account of initiator'
};

export const commerzbankTransactionsMapper = {
  'Transaction date': 'transactionDate',
  'Value date': 'bookingDate',
  'Transaction type': 'originalType',
  'Booking text': 'bookingText',
  Amount: 'amount',
  Currency: 'currency',
  'Account of initiator': 'initiatorAccount',
  'Bank code of account of initiator': 'initiatorAccountBankCode',
  'IBAN of account of initiator': 'initiatorIBAN'
};

export const commerzbankCreditCardTransactionsFileHeader = {
  0: 'Transaction date',
  1: 'Receipt',
  2: 'Company',
  3: 'Amount',
  4: 'Currency',
  5: 'Original Amount',
  6: 'Original Currency',
  7: 'Debited Credit Card'
};

export const commerzbankCreditCardTransactionsMapper = {
  'Transaction date': 'transactionDate',
  Receipt: 'bookingDate',
  Company: 'bookingText',
  Amount: 'amount',
  Currency: 'currency',
  'Original Amount': 'originalAmount',
  'Original Currency': 'originalCurrency',
  'Debited Credit Card': 'cardNumber'
};

export const transactionsHeaders = [
  amazonTransactionsFileHeader,
  commerzbankTransactionsFileHeader,
  commerzbankCreditCardTransactionsFileHeader
];

export const transactionsSources = [
  UPLOAD_FILE_SOURCE.COMMERZBANK,
  UPLOAD_FILE_SOURCE.AMAZON_LBB,
  UPLOAD_FILE_SOURCE.COMMERZBANK_CREDIT_CARD
];

export const transactionsMappers = [
  {
    source: UPLOAD_FILE_SOURCE.AMAZON_LBB,
    mapper: amazonTransactionsMapper,
    header: amazonTransactionsFileHeader
  },
  {
    source: UPLOAD_FILE_SOURCE.COMMERZBANK,
    mapper: commerzbankTransactionsMapper,
    header: commerzbankTransactionsFileHeader
  },
  {
    source: UPLOAD_FILE_SOURCE.COMMERZBANK_CREDIT_CARD,
    mapper: commerzbankCreditCardTransactionsMapper,
    header: commerzbankCreditCardTransactionsFileHeader
  }
];
