import { Button, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { FormField } from './FormField';
import { FIELD_TYPES } from './constants';
import { MATCH_FIELDS, MATCH_TYPES } from '../../../lib/constants';
import transformEnumToOptions from '../../../utils/transformEnumToOptions';
import { FETCH_CATEGORIES } from '../../../api/categories';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '100%',
    height: '3rem'
  },
  formLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '3rem'
  }
}));

function RuleForm({ isTriggerSubmit, handleFormSubmit, isTriggerClear, prefillData, ...props }) {
  const classes = useStyles();
  const methods = useForm();

  const matchFieldRef = useRef();
  const matchTypeRef = useRef();

  const { bookingText = {} } = prefillData || {};

  const { data: categoriesData } = useQuery(FETCH_CATEGORIES);
  const [categories, setCategories] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;

  const matchFieldsOptions = transformEnumToOptions(MATCH_FIELDS);
  const matchTypesOptions = transformEnumToOptions(MATCH_TYPES);

  useEffect(() => {
    if (categoriesData) {
      setCategories(
        categoriesData.allCategories.map((category) => ({
          value: category.id,
          label: category.name
        }))
      );
    }
  }, [categoriesData]);

  useEffect(() => {
    if (isTriggerSubmit) {
      handleSubmit(handleFormSubmit)();
    }
  }, [isTriggerSubmit]);

  useEffect(() => {
    if (isTriggerClear) {
      methods.reset();
    }
  }, [isTriggerClear]);

  useEffect(() => {
    if (matchFieldRef.current && matchTypeRef.current) {
      matchFieldRef.current.value = MATCH_FIELDS.BOOKING_TEXT;
      matchTypeRef.current.value = MATCH_TYPES.CONTAINS;
      matchFieldRef.current.disabled = true;
      matchTypeRef.current.disabled = true;
    }
  }, [matchFieldRef.current, matchTypeRef.current]);

  useEffect(() => {
    if (bookingText) {
      methods.setValue('matchField', MATCH_FIELDS.BOOKING_TEXT);
      methods.setValue('matchType', MATCH_TYPES.CONTAINS);
      methods.setValue('matchValue', bookingText);
    }
  }, [bookingText]);

  return (
    <FormProvider {...methods}>
      <form>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <span className={classes.formLabel}>Rule Name</span>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="name"
                control={control}
                placeholder="Enter rule name"
                classes={classes}
                type={FIELD_TYPES.TEXT_INPUT}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Description</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="description"
                control={control}
                placeholder="Enter rule description"
                classes={classes}
                type={FIELD_TYPES.TEXT_INPUT}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Match Field</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                id="matchField"
                name="matchField"
                ref={matchFieldRef}
                control={control}
                placeholder="Select match field"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={matchFieldsOptions}
                defaultValue={matchFieldsOptions[0].value}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Match Type</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                id="matchType"
                name="matchType"
                ref={matchTypeRef}
                control={control}
                placeholder="Select match type"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={matchTypesOptions}
                defaultValue={matchTypesOptions[0].value}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Match Value</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                id="matchValue"
                name="matchValue"
                control={control}
                placeholder="Enter match value"
                classes={classes}
                type={FIELD_TYPES.TEXT_INPUT}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Category</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="category"
                control={control}
                placeholder="Select category"
                classes={classes}
                type={FIELD_TYPES.SELECT}
                options={categories}
              />
            </Grid>

            <Grid item xs={3}>
              <div className={classes.formLabel}>
                <span>Rule is active</span>
              </div>
            </Grid>
            <Grid item xs={9}>
              <FormField
                name="active"
                control={control}
                placeholder="Is rule active"
                classes={classes}
                type={FIELD_TYPES.CHECKBOX}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
}

export default RuleForm;
